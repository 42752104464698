import dayjs from 'dayjs';
import { NotificationModel as NotificationType } from '../../graphql/generated';
import HtmlViewer from '../HtmlViewer';
import 'react-quill/dist/quill.snow.css';
import './Notification.css';

const Notification = ({ notification, localization }: { notification: NotificationType, localization: string }) => {
  const formattedDate = new Intl.DateTimeFormat(localization, {
    dateStyle: 'long',
  }).format(notification.publicationDate);

  return (
    <div
      className="notification"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        flexDirection: 'column',
      }}
    >
      <div className="notification-header-left-side">
        <span
          className="notification-category"
          style={{
            backgroundColor: notification.category.background,
            color: notification.category.color,
          }}
        >
          {notification.category.name}
        </span>
        <p className="notification-publication-date">{formattedDate}</p>
      </div>
      <div className="notification-body">
        <HtmlViewer
          template={`<p><span class="ql-font-roboto ql-size-large"><span class="ql-cursor">${String(
            notification.title
          )}</span></span></p>${String(notification.message)}`}
        />
      </div>
    </div>
  );
};

export default Notification;

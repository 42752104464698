import { RotatingLines } from 'react-loader-spinner';
import Notification from '../components/Notification/Notification';
import { useAvailableNotificationsQuery } from '../graphql/generated';
import { useWatchNotificationMutation } from '../graphql/generated';
import {useEffect, useMemo, useState} from 'react';
import {appendBodyScript, getUserId} from '../utils';
import {setGlobalTheme, ThemeColorModes} from '@atlaskit/tokens';

const MainPage = () => {
  const notificationsQuery = useAvailableNotificationsQuery();
  const [watchNotifications] = useWatchNotificationMutation();
  const currentUserId = useMemo(() => getUserId(), []);
  const notifications = notificationsQuery.data?.availableNotifications;
  const [isLoading, setIsLoading] = useState(false);

  const localization = useMemo(() => {
    if (!window.ParentConnector) return 'us'
    const { localization = 'us' } = window.ParentConnector.getDataParams()
    return localization
  }, [isLoading]);

  useEffect(() => {
    appendBodyScript({
      src: 'https://notification-connector-cdn.saasjet.com/ParentConnector.js',
      onload() {
        window.ParentConnector.init({
          onChangeTheme: (theme: ThemeColorModes) => {
            setGlobalTheme({ colorMode: theme }).catch(console.log);
          },
        })
        setIsLoading(true);
      },
    })
  }, []);

  useEffect(() => {
    if (notifications) {
      notifications
        ?.filter((notification) => {
          return !notification.watchedBy?.some(({ userId }) => userId === currentUserId);
        })
        .map((notification) => {
          void watchNotifications({
            variables: {
              id: notification.id || '',
            },
          });
        });
    }
  }, [notifications, watchNotifications]);

  return (
    <>
      {notifications && isLoading ? (
        [...notifications]
          .sort((a, b) => {
            return b.publicationDate - a.publicationDate;
          })
          .map((notification, key) => {
            return (
              <Notification key={`${notification.title}-${key}`} localization={localization} notification={notification} />
            );
          })
      ) : (
        <div
          style={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <RotatingLines
            strokeColor="grey"
            strokeWidth="5"
            animationDuration="0.75"
            width="96"
            visible={true}
          />
        </div>
      )}
    </>
  );
};

export default MainPage;

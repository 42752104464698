export const appendBodyScript = ({ dataset= {} , ...props }) => {
  const script = document.createElement('script');

  Object.assign(script, props);

  Object.entries(dataset)
    .forEach(([key, value]) => {
      script.dataset[key] = value as string;
    });

  document.body.appendChild(script);
}

import { makeVar, ReactiveVar } from '@apollo/client';
import { GlobalStateType } from '../types';

const initGlobalState: GlobalStateType = {
  isSearchPageOpen: false,
};

const globalStateVar: ReactiveVar<GlobalStateType> = makeVar(initGlobalState);

export { globalStateVar };

import ReactQuill from 'react-quill';

const HtmlViewer = ({ template }: { template: string }) => {
  return (
    <>
      {template ? (
        <ReactQuill value={template} modules={{ toolbar: false }} readOnly={true} />
      ) : (
        <> </>
      )}
    </>
  );
};

export default HtmlViewer;

import { HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const httpLink = new HttpLink({ uri: process.env.REACT_APP_NOTIFICATIONS_URI });

const authLink = setContext(() => {
  try {
    const params = location.pathname.split('/');
    const jwt = params.pop();
    const addonKey = params.pop();

    return {
      headers: {
        authorization: jwt ? `Bearer ${jwt}` : null,
        addon_key: addonKey,
      },
    };
  } catch (error) {
    console.log(error);
  }

  return {};
});

export const link = authLink.concat(httpLink);

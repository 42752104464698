/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type AccountModel = {
  __typename?: 'AccountModel';
  baseUrl: Scalars['String'];
  clientKey: Scalars['String'];
  id: Scalars['String'];
};

export type AuthorizationModel = {
  __typename?: 'AuthorizationModel';
  addonKey: Scalars['String'];
  id: Scalars['String'];
  jwtUrl: Scalars['String'];
};

export type CategoryModel = {
  __typename?: 'CategoryModel';
  background: Scalars['String'];
  color: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
};

export type CreateAccountInput = {
  /** Url for client jira */
  baseUrl: Scalars['String'];
  /** Jira client key */
  clientKey: Scalars['String'];
};

export type CreateAuthorizationRouteInput = {
  /** Addon key */
  addonKey: Scalars['String'];
  /** URL for jwt token verification */
  jwtUrl: Scalars['String'];
};

export type CreateCategoryInput = {
  /** Background of the category */
  background: Scalars['String'];
  /** Color of the category */
  color: Scalars['String'];
  /** Name of the category */
  name: Scalars['String'];
};

export type CreateDestinationInput = {
  /** Send notifications to listed accounts */
  toAccount?: InputMaybe<Array<Scalars['String']>>;
  /** Send notifications to all users */
  toAll: Scalars['Boolean'];
  /** Send notification to listed users */
  toUser?: InputMaybe<Array<Scalars['String']>>;
};

export type CreateNotificationInput = {
  /** Addon key for notification */
  addonKey: Scalars['String'];
  /** Category of notification ref */
  category: Scalars['String'];
  /** Date when notification is created */
  dateOfCreation: Scalars['Float'];
  /** Destination */
  destination: CreateDestinationInput;
  /** Display notification for new users */
  hideFromNewUsers: Scalars['Boolean'];
  /** Notification message */
  message: Scalars['String'];
  /** Date when notification is published */
  publicationDate: Scalars['Float'];
  /** Notification title */
  title: Scalars['String'];
  /** Users that viewed notification ref */
  watchedBy?: InputMaybe<Array<Scalars['String']>>;
};

export type CreateUserInput = {
  /** Jira client key ref */
  accountId: Scalars['String'];
  /** Jira user id */
  userId: Scalars['String'];
};

export type DestinationModel = {
  __typename?: 'DestinationModel';
  id: Scalars['String'];
  toAccount?: Maybe<Array<AccountModel>>;
  toAll: Scalars['Boolean'];
  toUser?: Maybe<Array<UserModel>>;
};

export type FindByAddonKeyInput = {
  /** Addon key */
  addonKey: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createAccount: AccountModel;
  createAuthorizationRoute: AuthorizationModel;
  createCategory: CategoryModel;
  createNotification: NotificationModel;
  createUser: UserModel;
  deleteNotification: Scalars['String'];
  dislikeNotification: NotificationModel;
  likeNotification: NotificationModel;
  updateCategory: CategoryModel;
  updateNotification: NotificationModel;
  watchNotification: NotificationModel;
};


export type MutationCreateAccountArgs = {
  createAccountInput: CreateAccountInput;
};


export type MutationCreateAuthorizationRouteArgs = {
  createNotificationInput: CreateAuthorizationRouteInput;
};


export type MutationCreateCategoryArgs = {
  createCategoryInput: CreateCategoryInput;
};


export type MutationCreateNotificationArgs = {
  createNotificationInput: CreateNotificationInput;
};


export type MutationCreateUserArgs = {
  createUserInput: CreateUserInput;
};


export type MutationDeleteNotificationArgs = {
  id: Scalars['String'];
};


export type MutationDislikeNotificationArgs = {
  id: Scalars['String'];
};


export type MutationLikeNotificationArgs = {
  id: Scalars['String'];
};


export type MutationUpdateCategoryArgs = {
  updateCategoryInput: UpdateCategoryInput;
};


export type MutationUpdateNotificationArgs = {
  updateNotificationInput: UpdateNotificationInput;
};


export type MutationWatchNotificationArgs = {
  id: Scalars['String'];
};

export type NotificationCreated = {
  __typename?: 'NotificationCreated';
  addon_key: Scalars['String'];
  event: Scalars['String'];
};

export type NotificationModel = {
  __typename?: 'NotificationModel';
  addonKey: Scalars['String'];
  category: CategoryModel;
  dateOfCreation: Scalars['Float'];
  destination: DestinationModel;
  hideFromNewUsers: Scalars['Boolean'];
  id: Scalars['String'];
  likedBy?: Maybe<Array<UserModel>>;
  message: Scalars['String'];
  publicationDate: Scalars['Float'];
  title: Scalars['String'];
  watchedBy?: Maybe<Array<UserModel>>;
};

export type Query = {
  __typename?: 'Query';
  accountByBaseUrl: AccountModel;
  accountByClientKey: AccountModel;
  accountById: AccountModel;
  accounts: Array<AccountModel>;
  authorizationRouteByAddonKey: AuthorizationModel;
  authorizationRoutes: Array<AuthorizationModel>;
  availableNotifications: Array<NotificationModel>;
  categories: Array<CategoryModel>;
  category: CategoryModel;
  notification: NotificationModel;
  notifications: Array<NotificationModel>;
  notificationsByAddonKey: Array<NotificationModel>;
  unreadNotificationCount: Scalars['Float'];
  user: UserModel;
  userById: UserModel;
  users: Array<UserModel>;
};


export type QueryAccountByBaseUrlArgs = {
  baseUrl: Scalars['String'];
};


export type QueryAccountByClientKeyArgs = {
  clientKey: Scalars['String'];
};


export type QueryAccountByIdArgs = {
  id: Scalars['String'];
};


export type QueryAccountsArgs = {
  ids?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryAuthorizationRouteByAddonKeyArgs = {
  findByAddonKeyInput: FindByAddonKeyInput;
};


export type QueryCategoryArgs = {
  name: Scalars['String'];
};


export type QueryNotificationArgs = {
  id: Scalars['String'];
};


export type QueryNotificationsByAddonKeyArgs = {
  addonKey: Scalars['String'];
};


export type QueryUserArgs = {
  id: Scalars['String'];
};


export type QueryUserByIdArgs = {
  userId: Scalars['String'];
};


export type QueryUsersArgs = {
  ids?: InputMaybe<Array<Scalars['String']>>;
};

export type Subscription = {
  __typename?: 'Subscription';
  notificationCreated: NotificationCreated;
};

export type UpdateCategoryInput = {
  /** Background of the category */
  background?: InputMaybe<Scalars['String']>;
  /** Color of the category */
  color?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  /** Name of the category */
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateNotificationInput = {
  /** Addon key for notification */
  addonKey?: InputMaybe<Scalars['String']>;
  /** Category of notification ref */
  category?: InputMaybe<Scalars['String']>;
  /** Date when notification is created */
  dateOfCreation?: InputMaybe<Scalars['Float']>;
  /** Destination */
  destination?: InputMaybe<CreateDestinationInput>;
  /** Display notification for new users */
  hideFromNewUsers?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
  /** Notification message */
  message?: InputMaybe<Scalars['String']>;
  /** Date when notification is published */
  publicationDate?: InputMaybe<Scalars['Float']>;
  /** Notification title */
  title?: InputMaybe<Scalars['String']>;
  /** Users that viewed notification ref */
  watchedBy?: InputMaybe<Array<Scalars['String']>>;
};

export type UserModel = {
  __typename?: 'UserModel';
  accountId: AccountModel;
  id: Scalars['String'];
  userId: Scalars['String'];
};

export type WatchNotificationMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type WatchNotificationMutation = { __typename?: 'Mutation', watchNotification: { __typename?: 'NotificationModel', id: string } };

export type AvailableNotificationsQueryVariables = Exact<{ [key: string]: never; }>;


export type AvailableNotificationsQuery = { __typename?: 'Query', availableNotifications: Array<{ __typename?: 'NotificationModel', id: string, title: string, message: string, dateOfCreation: number, publicationDate: number, hideFromNewUsers: boolean, addonKey: string, watchedBy?: Array<{ __typename?: 'UserModel', id: string, userId: string, accountId: { __typename?: 'AccountModel', id: string, clientKey: string, baseUrl: string } }> | null, category: { __typename?: 'CategoryModel', id: string, name: string, color: string, background: string }, destination: { __typename?: 'DestinationModel', id: string, toAll: boolean, toAccount?: Array<{ __typename?: 'AccountModel', id: string, clientKey: string, baseUrl: string }> | null, toUser?: Array<{ __typename?: 'UserModel', id: string, userId: string, accountId: { __typename?: 'AccountModel', id: string, clientKey: string, baseUrl: string } }> | null } }> };


export const WatchNotificationDocument = gql`
    mutation WatchNotification($id: String!) {
  watchNotification(id: $id) {
    id
  }
}
    `;
export type WatchNotificationMutationFn = Apollo.MutationFunction<WatchNotificationMutation, WatchNotificationMutationVariables>;

/**
 * __useWatchNotificationMutation__
 *
 * To run a mutation, you first call `useWatchNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWatchNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [watchNotificationMutation, { data, loading, error }] = useWatchNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWatchNotificationMutation(baseOptions?: Apollo.MutationHookOptions<WatchNotificationMutation, WatchNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WatchNotificationMutation, WatchNotificationMutationVariables>(WatchNotificationDocument, options);
      }
export type WatchNotificationMutationHookResult = ReturnType<typeof useWatchNotificationMutation>;
export type WatchNotificationMutationResult = Apollo.MutationResult<WatchNotificationMutation>;
export type WatchNotificationMutationOptions = Apollo.BaseMutationOptions<WatchNotificationMutation, WatchNotificationMutationVariables>;
export const AvailableNotificationsDocument = gql`
    query AvailableNotifications {
  availableNotifications {
    id
    title
    message
    dateOfCreation
    publicationDate
    hideFromNewUsers
    addonKey
    watchedBy {
      id
      userId
      accountId {
        id
        clientKey
        baseUrl
      }
    }
    category {
      id
      name
      color
      background
    }
    destination {
      id
      toAll
      toAccount {
        id
        clientKey
        baseUrl
      }
      toUser {
        id
        userId
        accountId {
          id
          clientKey
          baseUrl
        }
      }
    }
  }
}
    `;

/**
 * __useAvailableNotificationsQuery__
 *
 * To run a query within a React component, call `useAvailableNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAvailableNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<AvailableNotificationsQuery, AvailableNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AvailableNotificationsQuery, AvailableNotificationsQueryVariables>(AvailableNotificationsDocument, options);
      }
export function useAvailableNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AvailableNotificationsQuery, AvailableNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AvailableNotificationsQuery, AvailableNotificationsQueryVariables>(AvailableNotificationsDocument, options);
        }
export type AvailableNotificationsQueryHookResult = ReturnType<typeof useAvailableNotificationsQuery>;
export type AvailableNotificationsLazyQueryHookResult = ReturnType<typeof useAvailableNotificationsLazyQuery>;
export type AvailableNotificationsQueryResult = Apollo.QueryResult<AvailableNotificationsQuery, AvailableNotificationsQueryVariables>;
import './App.css';
import MainPage from './pages/MainPage';
import { Route, Switch } from 'react-router-dom';

const App = () => {
  return (
    <Switch>
      <Route path="/:addonKey/:jwt">
        <MainPage />
      </Route>
    </Switch>
  );
};

export default App;
